import axios from '@/shared/plugins/axios'
import utils from '@/shared/plugins/utils'
import enums from '@/shared/plugins/enums'
import store from '@/store'

const getDefaultState = () => {
    return {
        simulation_ref: null,
        // is_owner: false,
        // request_reference: null,
        // customer_reference: null,
        frontend_submission_time: null,
        budget: null,
        target_epc: null,
        results: {
            data: [],
            simulation: {
                started_at: null,
                finished_at: null,
                status: 'not_started', // "started", "finished", "failed", "pending"
                wait_time: null,
            },
            selectedPackage: 'best', // budget, custom, target, best
            renovations: [
                {
                    index: 0,
                    name: 'roofIn',
                    renovationCostName: 'roof_investment_cost',
                    selected: false,
                    disabled: false,
                },
                {
                    index: 1,

                    name: 'atticFloor',
                    renovationCostName: 'roof_investment_cost',
                    selected: false,
                    disabled: false,
                },
                {
                    index: 2,
                    name: 'wallExt',
                    renovationCostName: 'wall_investment_cost',
                    selected: false,
                    disabled: false,
                },
                {
                    index: 3,
                    name: 'windowsDouble',
                    renovationCostName: 'window_investment_cost',
                    selected: false,
                    disabled: false,
                },
                {
                    index: 4,
                    name: 'condGas',
                    renovationCostName: 'heating_investment_cost',
                    selected: false,
                    disabled: false,
                },
                {
                    index: 5,
                    name: 'heatPump',
                    renovationCostName: 'heating_investment_cost',
                    selected: false,
                    disabled: false,
                },
                {
                    index: 7,
                    name: 'wallCav',
                    renovationCostName: 'wall_investment_cost',
                    selected: false,
                    disabled: false,
                },
                {
                    index: 8,
                    name: 'unitPv',
                    renovationCostName: 'pv_investment_cost',
                    selected: false,
                    disabled: false,
                },
                {
                    index: 9,
                    name: 'roofExt',
                    renovationCostName: 'roof_investment_cost',
                    selected: false,
                    disabled: false,
                },
            ],
            renovationOptions: null,
            consumptions: {
                gas: {
                    you: null,
                    avg: null,
                },
                elec: {
                    you: null,
                    avg: null,
                },
            },
            selectedScenario: null,
        },
        simulation: {
            status: 'not_started',
            request_id: null,
            started_at: null,
            finished_at: null,
        },
    }
}

function renovations_from_scenario(scenario, all_renovations, features) {
    if (scenario.name === 'current') return []
    const renovationsNames = scenario.name.split('_')
    return renovationsNames.map((name) => {
        const currentRenovation = all_renovations.filter((renovation) => renovation.name === name)[0]
        const price = scenario[currentRenovation.renovationCostName]
        const title = currentRenovation.title
        // const tooltip = currentRenovation.tooltip
        const index = currentRenovation.index
        const area = {
            atticFloor: features.f_building_area * (1 - features.f_percent_of_roof_flat),
            roofIn: features.f_roof_area,
            roofExt: features.f_roof_area,
            unitPv: features.f_best_pane_area * 0.8,
            wallExt: features.exterior_wall_area,
            wallIn: features.exterior_wall_area,
            wallCav: features.exterior_wall_area,
            windowsDouble: null,
            condGas: null,
            heatPump: null,
        }[name]
        const extra_features = {
            atticFloor: { insulation_material: 'pur_pir' },
            roofIn: { insulation_material: 'pur_pir' },
            roofExt: { insulation_material: 'pur_pir' },
            unitPv: { pv_type: 'monocrystalline' },
            wallExt: { insulation_material: 'pur_pir' },
            wallIn: { insulation_material: 'pur_pir' },
            wallCav: { insulation_material: 'pur_pir' },
            windowsDouble: { window_type: 'hr_double' },
            condGas: {},
            heatPump: { heat_pump_type: 'air_water' },
        }[name]

        return {
            name,
            title,
            index,
            price,
            area,
            subsidy: null,
            selected: true,
            extra_features,
        }
    })
}

const ersStore = {
    namespaced: true,
    state: { ...getDefaultState() },
    getters: {
        getSimulationRef(state) {
            return state.simulation_ref
        },
        getScenariosAvailable(state) {
            return state.results.data.length > 1
        },
        getBudget(state) {
            return state.budget
        },
        getTargetEpc(state) {
            return state.target_epc
        },
        getCurrentScenario(state) {
            return state.results.data.filter((scenario) => scenario.name === 'current')[0]
        },
        getEpcPercents: (_state, getters) => (region) => {
            const currentScenario = getters.getCurrentScenario

            return utils.epc_percent_mapping(region, currentScenario.epc_ind)
        },
        getTargetEpcScenario:
            (_state, getters) =>
            (features, roundedEpcTarget = 350) => {
                const min_epc_ind = Math.min(...getters.getScenarios.map((s) => s.epc_ind))
                const scenarios = [
                    ...getters.getScenarios.filter(
                        (scenario) =>
                            Math.round(scenario.epc_ind) <=
                                Math.max(roundedEpcTarget, Math.round(min_epc_ind)) &&
                            scenario.name !== 'current'
                    ),
                ]

                const targetEpcScenario = scenarios.reduce((prev, current) =>
                    Math.abs(roundedEpcTarget - prev.total_investment_cost) <
                    Math.abs(roundedEpcTarget - current.total_investment_cost)
                        ? prev
                        : current
                )

                return {
                    ...targetEpcScenario,
                    renovations: renovations_from_scenario(
                        targetEpcScenario,
                        getters.getRenovations,
                        features
                    ),
                }
            },
        // getBestEpcScenario: (state, getters) => (features) => {
        //     const bestScenario = getters.getScenarios.reduce((prev, current) =>
        //         prev.epc_ind < current.epc_ind ? prev : current
        //     )

        //     return {
        //         ...bestScenario,
        //         renovations: renovations_from_scenario(
        //             bestScenario,
        //             getters.getRenovations,
        //             features
        //         ),
        //     }
        // },
        getSelectedScenario(state) {
            return state.results.selectedScenario || state.results.data[0] // if no scenario available return the first one (current scenario)
        },
        getSelectedPackage(state) {
            return state.results.selectedPackage
        },
        getRenovationOptions(state) {
            return state.results.renovationOptions
        },
        // getHeatingFuel(state) {
        //     return (
        //         state.features.heating_attributes[0].fuel ||
        //         state.features.heating_attributes[0].heating_type
        //     )
        // },
        getSimulation(state) {
            return state.simulation
        },
        getRenovations(state) {
            return state.results.renovations
        },
        getRenovationsSummary:
            (state, getters) =>
            ({ f_construction_year, region }) => {
                if (!f_construction_year || !region) return
                if (state.results.data.length === 1) return
                const currentPackage = state.results.selectedPackage
                const currentEpcScore = state.results.data.find(
                    (scenario) => scenario.name === 'current'
                ).epc_ind

                const currentScenario = state.results.data.filter(
                    (scenario) => scenario.name === 'current'
                )[0]

                const computeRenovationPriceVat = (name, price) => {
                    const isOlderThanTenYo = new Date().getFullYear() - f_construction_year > 10
                    const isFlanders = region === enums.iso_regions.Flanders
                    const isEligibleRenovation = ['heatPump', 'unitPv'].includes(name)
                    const priceVat =
                        isOlderThanTenYo && isFlanders && isEligibleRenovation
                            ? price - (price / 100) * 15
                            : price
                    return priceVat
                }
                if (['target', 'best', 'budget'].includes(currentPackage)) {
                    const renovations = state.results.selectedScenario.renovations
                    const prices = renovations.map((reno) => {
                        return getters.getCostFromRenovation(reno.name)
                    })
                    const subsidies = renovations.map((r) => {
                        return computeRenovationPriceVat(r.name, r.price)
                    })
                    const totalPrice = prices.reduce((total, num) => total + num, 0)
                    const totalSubsidies = subsidies.reduce((total, num) => total + num, 0)
                    return {
                        renovations: renovations.map((renovation) => ({
                            title: renovation.title,
                            price: renovation.price,
                            name: renovation.name,
                            subsidy: computeRenovationPriceVat(renovation.name, renovation.price),
                            afterSubsidy: computeRenovationPriceVat(renovation.name, renovation.price),
                        })),
                        total: {
                            title: 'Total',
                            price: totalPrice,
                            subsidy: totalSubsidies,
                            afterSubsidies: totalSubsidies,
                        },
                        futureEpcScore: state.results.selectedScenario.epc_ind,
                        epcSaving: Math.abs(currentScenario.epc_ind - state.results.selectedScenario.epc_ind),
                        co2Savings: Math.abs(
                            state.results.selectedScenario.total_co2 - currentScenario.total_co2
                        ),
                        yearlySavings: state.results.selectedScenario.yearly_savings,
                        labels: [
                            utils.computeEpcLabelFromScore(currentEpcScore, region).label,
                            utils.computeEpcLabelFromScore(state.results.selectedScenario.epc_ind, region)
                                .label,
                        ],
                    }
                } else {
                    const selectedRenovations = state.results.renovations.filter((r) => r.selected)
                    const prices = selectedRenovations.map((reno) => {
                        const priceFromSelectedScenario = getters.getCostFromRenovation(reno.name)
                        return priceFromSelectedScenario
                    })
                    const subsidies = selectedRenovations.map((r) => {
                        const price = state.results.data.find(
                            (scenario) => scenario.name === r.name
                        ).total_investment_cost
                        return computeRenovationPriceVat(r.name, price)
                    })
                    const totalPrice = prices.reduce((total, num) => total + num, 0)
                    const totalSubsidies = subsidies.reduce((total, num) => total + num, 0)

                    // const compare = (a, b) => a.sort().join(',') === b.sort().join(',')

                    const selectedScenario = state.results.selectedScenario

                    return {
                        renovations: selectedRenovations.map((renovation, i) => ({
                            title: renovation.title,
                            name: renovation.name,
                            price: prices[i],
                            subsidy: computeRenovationPriceVat(renovation.name, prices[i]),
                            afterSubsidy: 0,
                        })),
                        total: {
                            title: 'Total',
                            price: totalPrice,
                            subsidy: totalSubsidies,
                            afterSubsidies: totalPrice - totalSubsidies,
                        },
                        futureEpcScore: selectedScenario?.epc_ind || currentEpcScore,
                        epcSaving: Math.abs(
                            currentScenario.epc_ind - (selectedScenario?.epc_ind || currentEpcScore)
                        ),
                        co2Savings: Math.abs(currentScenario.co2_savings),
                        yearlySavings: state.results.selectedScenario.yearly_savings,
                        labels: [
                            utils.computeEpcLabelFromScore(currentEpcScore, region).label,
                            utils.computeEpcLabelFromScore(
                                selectedScenario?.epc_ind || currentEpcScore,
                                region
                            ).label,
                        ],
                    }
                }
            },
        getPropertyValue(state) {
            return Math.round(state.results.selectedScenario.re_value_increase / 1000) * 1000
        },
        getScenarios(state) {
            return state.results.data
        },
        getScenariosNames(state) {
            return state.results.data.map((scenario) => scenario.name)
        },
        getSimulationStatus(state) {
            return state.results.simulation.status
        },
        getOtherData(state) {
            return state.other_data
        },
        getEffectiveLevel(state) {
            return state.address_text.effective_level
        },
        getBudgetScenario: (state) => (features, price) => {
            // get all scenarios
            let scenarios = state.results.data
            // remove "current" scenario
            scenarios = scenarios.filter((scenario) => scenario.name !== 'current')
            // keep only the ones that are under a threshold (price)
            scenarios = scenarios.filter((scenario) => scenario.total_investment_cost <= price)

            // sort them again by epc value (decreasing)
            scenarios = scenarios.sort((a, b) => {
                if (a.epc_ind < b.epc_ind) {
                    return 1
                } else {
                    return -1
                }
            })
            // take the last one (the best EPC value)
            const scenario = scenarios.slice(-1)[0]

            if (!scenario) return

            return {
                ...scenario,
                renovations: renovations_from_scenario(scenario, state.results.renovations, features),
            }
        },
        getSelectedRenovationList: (state, getters) => (features) => {
            return renovations_from_scenario(
                getters.getSelectedScenario,
                state.results.renovations.filter((renovation) => renovation.selected),
                features
            )
        },
        getCostFromRenovation: (state, getters) => (renovationName) => {
            return getters.getSelectedScenario[
                getters.getRenovations.find((reno) => reno.name === renovationName).renovationCostName
            ]
        },
        getVisibleRenovationList(state, getters) {
            const getRenovationByName = (renovationName) => {
                const currentScenario = getters.getScenarios.find(
                    (scenario) => scenario.name === renovationName
                )
                const currentRenovation = getters.getRenovations.find(
                    (renovation) => renovation.name === renovationName
                )
                const priceFromSelectedScenario = getters.getCostFromRenovation(renovationName)
                const priceFromDefaultScenario = currentScenario.total_investment_cost
                const price = currentRenovation.selected
                    ? priceFromSelectedScenario
                    : priceFromDefaultScenario
                // const index = currentRenovation.index
                // const title = currentRenovation.title
                // const tooltip = currentRenovation.tooltip

                return {
                    ...currentRenovation,
                    name: renovationName,
                    price,
                    subsidy: 123,
                    selected: currentRenovation.selected,
                    tooltip: null, // FIXME: fix UI
                }
            }

            const renovations = Object.values(getters.getRenovationOptions)
                .map((item) =>
                    item.map((renovationName) => {
                        return getRenovationByName(renovationName)
                    })
                )
                .flat()

            // let renovations = []
            // Object.keys(getters.getRenovationOptions).forEach((key) => {
            //     getters.getRenovationOptions[key].forEach((item) => {
            //         renovations.push(getRenovationByName(item))
            //     })
            // })
            return renovations
        },
        getMinMaxBudget(state, getters) {
            let scenarios = [...getters.getScenarios]
            const compareTotalInvestmentCost = (a, b) => {
                if (a.total_investment_cost < b.total_investment_cost) {
                    return -1
                }
                if (a.total_investment_cost > b.total_investment_cost) {
                    return 1
                }
                return 0
            }
            if (scenarios.length > 1) {
                scenarios = scenarios
                    .sort(compareTotalInvestmentCost)
                    .filter((scenario) => scenario.total_investment_cost > 0)
            }

            const min = scenarios[0].total_investment_cost
            const max = scenarios[scenarios.length - 1].total_investment_cost

            return {
                min,
                max,
                sorted: scenarios.map((scenario) => scenario.total_investment_cost),
            }
        },
        computeProtectedVolume(state, getters) {
            const {
                f_inner_volume,
                attic_floor_area,
                effective_level,
                f_living_area,
                is_attic,
                is_attic_heated,
            } = getters.getFeatures

            if (effective_level < '3') {
                return f_living_area * enums.const.floor_height
            } else if (is_attic && !is_attic_heated) {
                return (
                    f_inner_volume -
                    attic_floor_area * enums.const.attic_height * enums.const.attic_volume_factor
                )
            } else {
                return f_inner_volume
            }
        },
    },
    mutations: {
        SET_SIMULATION_REF(state, ref) {
            state.simulation_ref = ref
        },
        SET_BUDGET(state, val) {
            state.budget = +val
        },
        SET_TARGET_EPC(state, val) {
            state.target_epc = +val
        },
        // SET_IS_OWNER(state, value) {
        //     state.is_owner = value
        // },
        FLAG_SUBMISSION(state, flag = true) {
            if (flag) {
                state.frontend_submission_time = new Date()
            } else {
                state.frontend_submission_time = null
            }
        },
        RESET_STORE(state) {
            Object.assign(state, { ...getDefaultState() })
        },
        // SET_ADDRESS_TEXT(state, address) {
        //     Object.assign(state.address_text, address)
        //     let box_appendix = state.address_text.boxnumber
        //         ? ` b ${state.address_text.boxnumber}`
        //         : ''
        //     if (state.address_text.postalcode !== null) {
        //         state.address_text.full_address = `${state.address_text.streetname} ${state.address_text.streetnumber}${box_appendix}, ${state.address_text.postalcode}`
        //     } else {
        //         state.address_text.full_address = ''
        //     }
        // },
        SET_SIMULATION(state, simulation_data) {
            if (
                simulation_data.status != 'started' &&
                simulation_data.request_id != state.simulation.request_id
            ) {
                return
            }
            Object.assign(state.simulation, simulation_data)
        },
        SET_RENOVATION_CARD_SELECTED(state, index) {
            state.results.renovations = state.results.renovations.map((item) => {
                if (item.index === index) {
                    item.selected = !item.selected
                }
                return item
            })
        },
        SET_SELECTED_SCENARIO(state, scenario) {
            state.results.selectedScenario = scenario
        },
        SET_SELECTED_PACKAGE(state, { packageName, scenario }) {
            state.results.selectedPackage = packageName
            if (scenario) {
                state.results.selectedScenario = scenario
                state.results.renovations = state.results.renovations.map((renovation) => {
                    const selected = scenario.renovations.map((r) => r.name).includes(renovation.name)
                    return {
                        ...renovation,
                        selected,
                    }
                })
            }
        },
        SET_RENOVATION_SELECTED(state, { index, value }) {
            state.results.renovations = state.results.renovations.map((item) => {
                if (item.index === index) {
                    item.selected = value
                }
                return item
            })
        },
        RESET_SIMULATION_STATE(state) {
            state.results.simulation = getDefaultState().results.simulation
        },
        SET_RESULT_DATA(state, data) {
            state.results.data = data.scenarios

            // if we removed scenarios from the list, renovation options could no be consistent anymore
            const scenarioNames = data.scenarios
                .map((scenario) => scenario.name)
                .filter((scenarioName) => scenarioName !== 'current')

            let filteredRenovationOptions = {}

            let renovationOptions = data.renovation_options

            for (let renovationType in renovationOptions) {
                if (renovationType in renovationOptions) {
                    filteredRenovationOptions[renovationType] = []
                    let renovationArray = renovationOptions[renovationType]
                    renovationArray.map((renovationName) => {
                        if (scenarioNames.includes(renovationName)) {
                            filteredRenovationOptions[renovationType].push(renovationName)
                        }
                    })
                }
            }

            state.results.renovationOptions = filteredRenovationOptions
        },
        SET_SIMULATION_STATUS(state, obj) {
            state.results.simulation = {
                ...state.results.simulation,
                ...obj,
            }
        },
    },
    actions: {
        async fetch_simulations(context, request_ref) {
            const config = store.getters.getConfig
            console.debug('fetching simulations for request', config)
            try {
                const { data: simulations } = await axios.get(
                    utils.urlJoin(config.VALUATION_API_URL, `request/${request_ref}/energy-simulations`)
                )
                return simulations
            } catch (e) {
                console.log(e)
            }
        },
        async fetch_latest_simulation({ commit, dispatch }, request_ref) {
            // FIXME: this apparently gets called in cases where there are no simulations at all (might be a problem with displaying result page when it shouldn't be)

            const config = store.getters.getConfig

            let simulations = await dispatch('fetch_simulations', request_ref)

            simulations = simulations.map((simulation) => ({
                ...simulation,
                created_at: new Date(simulation.created_at),
            }))

            const latestSimulationRef = simulations.sort(
                (a, b) => b.created_at.getTime() - a.created_at.getTime()
            )[0].ref

            commit('SET_SIMULATION_REF', latestSimulationRef)

            const { data } = await axios.get(
                utils.urlJoin(
                    config.VALUATION_API_URL,
                    `energy-simulation/${latestSimulationRef}?details=full_with_request`
                )
            )
            return {
                data,
                simulation_ref: latestSimulationRef,
            }
        },
        async fetch_simulation(state, simulation_ref) {
            const config = store.getters.getConfig

            return await axios.get(
                utils.urlJoin(
                    config.VALUATION_API_URL,
                    `energy-simulation/${simulation_ref}?details=full_with_request`
                )
            )
        },
        // TODO: make this promise-based
        async load_simulation({ dispatch, commit, getters, state }, { requestRef, simulationRef, route }) {
            // const { route } = payload
            // const request_ref = route.query.ref

            const { data } = simulationRef
                ? await dispatch('fetch_simulation', simulationRef)
                : await dispatch('fetch_latest_simulation', requestRef)

            const { simulation_result, renovation_options, saved_scenario, valuation_request, ref } = data

            // commit('RESET_FEATURES')

            commit('SET_SIMULATION_REF', ref)

            const { scenarios } = simulation_result

            // commit('SET_REQUEST_REF', valuation_request_ref)

            // commit('SET_ADDRESS_TEXT', address)

            // commit('SET_IS_OWNER', owner.is_self)

            commit('SET_RESULT_DATA', { scenarios, renovation_options })

            if (route.query.package && getters.getScenariosAvailable) {
                const scenario = getters.getScenarios.find(
                    (scenario) => scenario.name === route.query.scenario
                )
                if (route.query.budget) {
                    commit('SET_BUDGET', route.query.budget)
                }
                if (route.query.targetEpc) {
                    commit('SET_TARGET_EPC', route.query.targetEpc)
                }
                commit('SET_SELECTED_PACKAGE', {
                    packageName: route.query?.package,
                    scenario: {
                        ...scenario,
                        renovations: renovations_from_scenario(
                            scenario,
                            state.results.renovations,
                            valuation_request.features
                        ),
                    },
                })
            } else if (saved_scenario && getters.getScenariosAvailable) {
                // load saved scenario if available
                const { package_name, budget, scenario_name, target_epc } = saved_scenario
                let scenario = {}

                if (package_name === 'custom') {
                    const selectedScenario = state.results.data.find(
                        (scenario) => scenario.name === scenario_name
                    )
                    scenario = {
                        ...selectedScenario,
                        renovations: renovations_from_scenario(
                            selectedScenario,
                            state.results.renovations,
                            valuation_request.features
                        ),
                    }
                } else {
                    scenario =
                        package_name === 'budget'
                            ? getters.getBudgetScenario(valuation_request.features, budget)
                            : getters.getTargetEpcScenario(valuation_request.features, target_epc)
                }

                commit('SET_SELECTED_PACKAGE', {
                    packageName: package_name,
                    scenario,
                })

                commit('SET_BUDGET', budget)
                commit('SET_TARGET_EPC', target_epc)
            } else if (getters.getScenariosAvailable) {
                // else we choose budget package with minimum value by default
                commit('SET_BUDGET', getters.getMinMaxBudget.min)
                commit(
                    'SET_TARGET_EPC',
                    Math.round(
                        getters.getTargetEpcScenario(
                            valuation_request.features,
                            getters.getCurrentScenario.epc_ind
                        ).epc_ind
                    )
                )
                commit('SET_SELECTED_PACKAGE', {
                    packageName: 'budget',
                    scenario: getters.getBudgetScenario(valuation_request.features, getters.getBudget),
                })
            }

            commit('SET_SIMULATION_STATUS', {
                status: 'finished',
            })
        },

        // TODO: check if we still need this post-treatment when submitting ERS request:
        //     if (
        //         // level 0.7 is an ambigous case where multiple buildings can be on the same parcel. the level in the address blob can be different so we force the effective_level to follow the features level instead of the address one
        //         .level === '0.7' &&
        //         context.getters.effective_level !== '0.7'
        //     ) {
        //         context.commit('SET_EFFECTIVE_LEVEL', 0.7)
        //         feature.effective_level = context.getters.getEffectiveLevel
        //     }
        // if ('heating_attributes' in feature) {
        //     if (feature.heating_attributes[0].fuel === 'district_heating') {
        //         feature.heating_attributes[0].fuel = null
        //     }
        // }
        // },
    },
}

export default ersStore
