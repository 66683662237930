export const catalogChildrenRoutes = {
    '3d': {
        label: '3D',
        component: () => import('@/apps/Catalog/View/Map/3DMap.vue'),
    },
    '2d': {
        label: '2D',
        component: () => import('@/apps/Catalog/View/Map/2DMap.vue'),
    },
    'street-view': {
        label: 'Street View',
        component: () => import('@/apps/Catalog/View/Map/2DMap.vue'),
    },
}

export const createCatalogRoute = () => ({
    path: '/catalog',
    name: 'catalog',
    redirect: () => {
        return { name: 'catalog-start' }
    },
    component: () => import('@/apps/Catalog/Catalog.vue'),
    children: [
        {
            path: 'start',
            name: 'catalog-start',
            component: () => import('@/apps/Catalog/Start.vue'),
        },
        {
            path: 'view',
            name: 'catalog-view',
            component: () => import('@/apps/Catalog/View/View.vue'),
            children: Object.keys(catalogChildrenRoutes).map((key) => ({
                path: key,
                name: `catalog-view-${key}`,
                component: catalogChildrenRoutes[key].component,
                meta: {
                    tabValue: key,
                    tabLabel: key,
                },
            })),
        },
        {
            path: 'report',
            name: 'catalog-report',
            component: () => import('@/apps/Catalog/Report.vue'),
        },
    ],
})
